/* For all pages */
.all-page-container {
    /* height: 50%; */
    background: #fffafa;
    /* max-height: 2500px; */
    /* margin: auto; */
    /* width: auto; */
    display: flex;
    justify-content: center;
    padding: 20px;
}