.contactme-page {
  background-color: #fffafa;
  height: 78.6vh;
  /* box-sizing: border-box; */
  /* border-color: black;
  border-width: 50px; */
}

.App {
  text-align: center;
  max-width: 400px;
  margin: 100px auto;
  background-color: #ffffff;
}

Form {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  margin-top: -70px;
}

.contactme-title-box {
  padding-top: 20px;
  /* line-height: 100px; */
}

.contactme-title {
  font-size: 400%;
  text-decoration: underline;
}

.contactme-description-box {
  text-align: center;
  padding-top: 30px;
}

.contactme-description1 {
  font-size: 125%;
  margin-bottom: -10px;
  text-align: center;
  padding-top: 30px;
}

.contactme-description2 {
  font-size: 125%;
  padding-top: 40px;
  text-align: center;
}
