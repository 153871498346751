/*-------------------------------- Cards Page CSS Setting --------------------------------*/

.cards {
  padding: 3rem;
  background: #fffafa;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  /* width: 100%; */
}

.borders{
  border-color: black;
  border-width: 2px;
}

/*-----------------------------------------------------------------------------*/
/*-------------------------------- Image logos only --------------------------------*/

.tmo-logo {
  width: 50%;
  margin: auto;
  display: block;
}

.ttp-logo {
  width: 50%;
  margin: auto;
  display: block;
}

.google-logo, .bglogo {
  width: 60%;
  margin: auto;
  display: block;
}

.ckmlogo, .bc-logo, .lagcc-logo {
  width: 40%;
  margin: auto;
  display: block;
}

.player{
    padding-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 650px;
}

/*-----------------------------------------------------------------------------*/
/*-------------------------------- Cards and Pargraphs --------------------------------*/

.left-card, .right-card {
  /* background-color: bisque; */
  flex: 40%;
}

.text-description-right {
  /* background-color: blueviolet; */
  font-family: 'Montserrat', sans-serif;
  align-self: center;
  text-align: justify;
  flex: 40%;
  margin-left: auto;
  margin-right: auto;
}

.text-description-left {
  /* background-color: azure;  */
  font-family: 'Montserrat', sans-serif;
  align-self: center;
  text-align: justify;
  flex: 40%;
}

/*-----------------------------------------------------------------------------*/

h1 {
  text-align: center;
}

/*-------------------------------- Mobile Settings --------------------------------*/

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}

/*-----------------------------------------------------------------------------*/