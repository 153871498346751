
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.skills {
  background-image: url('/public/images/under-construction-website.jpeg');
  background-position: center;
  background-size: cover;
  color: #fffb00;
  font-size: 100px;
  font-weight: bolder;
  display: flex;
  height: 78.6vh;
  align-items: center;
  justify-content: center;
  font-size: 6rem;
}
